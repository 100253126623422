import React from 'react';
import './Testimonials.css';

const testimonials = [

    {
        name: "Dr Kami Saedi",
        location: "London, UK",
        testimonial: `I'm a retired psychiatrist. I've known Professor Abbas Edalat for a number of years and been familiar with his work on self-attachment techniques(SAT) at Imperial College. I've had the advantage of having done the SAT protocol. For me, it was a transformative experience. It changed my view of my childhood experiences and created a lot of self-compassion...`,
        videoLink: "http://humandevelopment.doc.ic.ac.uk/videos/testimonial_kami.mp4"
    },

    {
        name: "Maryam",
        location: "Rochester, NY",
        testimonial: `The most important and tangible achievement I had in the Self-Attachment workshop is about one of my old obsessive habits. I used to obsessively play with my hairs in stressful situations that after exercising the protocols, it got disappeared.

        Another sweet effect of this workshop on me was creating the opportunity to get in touch with my inner-child (through spending time with my childhood photos) in a very active, emotional and loving manner. This, made me to have a higher-level experience of loving myself and pure joy and happiness that made me to be more compassionate and happier with who I am.

        The workshop’s lessons have helped me to contemplate and develop more efficient philosophy of life as I learned the essence of some of the greatest life values in this workshop.

        Moreover, the workshop has given me sets of tools to encounter unpleasant and complicated situations in life that has helped me to handle those situations better, so far.`,
    },
    {
        name: "Farnaz",
        location: "Montreal, CA",
        testimonial: `I would like to write few lines here just to let you know about my experience with regards to Self-attachment protocols.

        In difficult stressful situations, since I communicate with my childhood I share more compassion and kindness, therefore I am more effective with my break throughs.

        I really enjoy my loneliness and I can happily travel alone make good memory out of each single moment of it. The reason is I am in peace with myself and am trying to keep my child happy and energetic and positive.

        I overcome my fears since I can see that it is my childhood who is frightened and I can help her with love and confidence. I can manage to accept new challenges way much better now.

        I am more creative now. As an example, recently I give my friends gifts that are my handcrafts. I enjoy it and they love their gifts.

        I accept the others as they are and try to coordinate my relationship with them in peaceful manner. As an example, I communicate with my father in a much better way. We laugh together deeply and we effectively also talk about serious topics as well.

        I laugh a lot to every difficulty and non-conformity happens every moment.`,
    },
    {
        name: "Farnaz",
        location: "Montreal, CA",
        testimonial: `I would like to write few lines here just to let you know about my experience with regards to Self-attachment protocols.

        In difficult stressful situations, since I communicate with my childhood I share more compassion and kindness, therefore I am more effective with my break throughs.

        I really enjoy my loneliness and I can happily travel alone make good memory out of each single moment of it. The reason is I am in peace with myself and am trying to keep my child happy and energetic and positive.

        I overcome my fears since I can see that it is my childhood who is frightened and I can help her with love and confidence. I can manage to accept new challenges way much better now.

        I am more creative now. As an example, recently I give my friends gifts that are my handcrafts. I enjoy it and they love their gifts.

        I accept the others as they are and try to coordinate my relationship with them in peaceful manner. As an example, I communicate with my father in a much better way. We laugh together deeply and we effectively also talk about serious topics as well.

        I laugh a lot to every difficulty and non-conformity happens every moment.`,
    },
    {
        name: "Mehdi",
        location: "Vancouver, CA",
        testimonial: `I have learned more techniques to promote positive emotions inside me. The main one was laughing. Also to be more accepting.`,
    },
    {
        name: "Ellie",
        location: "Vancouver, CA",
        testimonial: `What I achieved from this workshop ( by becoming aware of the protocols and practicing them ) was I learned how I can laugh even at the unfortunate events in my life. I learned the power of laughter and how to laugh for no reason! And I realized a good, hearty laugh relieves physical tension and stress in me. I also learned how to get creative by changing the way I think about issues.
        The Self-Attachment workshop taught me new tools to achieve Self-Actualization.`,
    },
    {
        name: "Maryam",
        location: "Canada",
        testimonial: `Before this workshop, that was difficult for me to respond negatively to others' demands. I was trying to fix it, but I was not very successful. I discovered  my behavior source during the workshop so I could understand the principle of my behavior and after that, I achieve to solve that.`,
    },
    {
      name: "Farnaz",
      location: "Vancouver, CA",
      testimonial: `I always experienced anxiety at different stages in my life. I never knew how to manage it from when I was a teenager. Self attachment theory gave me the opportunity to get in contact with my childhood self and get to know her better. Her fears and anxieties are more understandable to me and with using laugh protocol I learned how to be more creative to solve my problems.`,
    },
    {
      name: "Roushan",
      location: "Vancouver, CA",
      testimonial: `The Self-attachment workshop was an opportunity for me to revisit and revive many of the healthier mental, emotional and behavioral habits I held when I was a child; habits that I had gradually lost touch with in my “adult” life, such as pure and unrestrained experience of joy, play and passion, as well as putting aside time and effort to contemplate fundamental questions about my philosophy of life.
      The exercises gave me a framework for a fresh and more mature understanding of the concept of self-compassion, which has helped me improve the quality of my day-to-day being. It has improved my ability for mindful and open-hearted acceptance of myself and others, facilitated constructive and more intimate connections with my loved ones, and has given me more tools to deal with difficult situations and people more constructively.
      The long-term outcome of this workshop for me includes the development and refinement of a set of tools and skills for mental, emotional and social self-regulation, which I am adapting based on some of the exercises and which I plan to continue to incorporate in my life.`,
    },
    {
        name: "Haniyeh",
        location: "Canada",
        testimonial: `It provided me with the chance of meeting with myself and who I am. It reminded me focusing on my inner needs and knowing them. I tried to make a new relationship with myself in a friendly way as a matured mother and forgive myself and my parent with all defects exist. I learnt I have to keep myself happy and smiley as no one else does it for me. I started to love myself and grow it as a mission in my life. I knew I shouldn’t get stock in the world’s disasters because I would just loose my life. Now I try to accept what I can’t change and at the same time work on my goals to improve my life, while an inner sound tells me “cool down”, “ take it easy”, at the same time.`,
    },
    {
      name: "Niloufar",
      location: "Vancouver, CA",
      testimonial: `Participating in the "Evaluation of Self-attachment for Enhancing Positive Affects" workshop was the best opportunity I came across this year.
      Practicing this protocol, not only helped me to discover the rationales behind my personality and know my inner-self better but also empowered me to improve myself every moment and become a better version of myself.
      All been said, I can not ignore the fact that the way Dr. Edalat explained and elaborated the subject in his passionate way is one of the success factors of this workshop.`,
    },

];

const Testimonials = () => {
    return (
        <div className="container">
            {testimonials.map((testimonial, index) => (
                <div key={index} className={index % 2 === 0 ? "medium" : "dark"}>
                    <div className="text-div">
                        <p className="description">{testimonial.testimonial}</p>
                    </div>
                    {testimonial.videoLink && (
                        <div className="video-container">
                            <a 
                                href={testimonial.videoLink} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="publication-button"
                            >
                                Watch Video
                            </a>
                        </div>
                    )}
                    <div className="text-div author">
                        - {testimonial.name}, {testimonial.location}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Testimonials;
